const drivers = [
    { student_id: 1, name: "Ozzy Domarkas", time: 22.204 },
    { student_id: 2, name: "Jamie Doyle", time: 22.332 },
    { student_id: 3, name: "Rian Doyle", time: 22.249 },
    { student_id: 4, name: "Peter Stanev", time: 22.341 },
    { student_id: 5, name: "Jamie Tobin", time: 22.733 },
    { student_id: 6, name: "Hannah O'Connor", time: 22.395 },
    { student_id: 7, name: "Nathan McNamara", time: 24.325 },
    { student_id: 8, name: "Justin Malins", time: 22.574 },
    { student_id: 9, name: "Eoghan Dowling", time: 23.566 },
    { student_id: 10, name: "Darragh Whitney", time: 22.961 },
    { student_id: 11, name: "Dylan Shupe", time: 22.992 },
    { student_id: 12, name: "Ronan Duggan", time: 23.162 },
    { student_id: 13, name: "Lara Morosso", time: 25.416 },
    { student_id: 14, name: "Sam Boyle", time: 22.546 },
    { student_id: 15, name: "Ben Power", time: 22.572 },
    { student_id: 16, name: "Ryan Sexton", time: 23.008 },
    { student_id: 17, name: "Richie Murphy", time: 21.974 },
    { student_id: 18, name: "Ellen Donnelly", time: 22.249 },
    { student_id: 19, name: "Jamie Halloran", time: 22.875 },
    { student_id: 20, name: "Dylan Rockett", time: 23.035 },
    { student_id: 21, name: "Dean Horgan", time: 25.29 },
    { student_id: 22, name: "Niko Wisniewski", time: 22.938 },
    { student_id: 23, name: "Jack Gear", time: 22.531 },
    { student_id: 24, name: "Ryan Price", time: 24.694 },
    { student_id: 25, name: "Harry Watkins", time: 23.51 },
    { student_id: 26, name: "Adam Crales", time: 24.553 },
    { student_id: 27, name: "Patryk Pawlak", time: 24.576 },
    { student_id: 28, name: "Cillian O'Connor", time: 22.482 },
    { student_id: 29, name: "Simon Harte", time: 23.004 },
    { student_id: 30, name: "Leon Wallis", time: 23.139 },
    { student_id: 31, name: "Ryan Price", time: 23.282 },
    { student_id: 32, name: "Nathan Fitz", time: 23.426 },
    { student_id: 33, name: "Josh Murphy", time: 23.842 },
    { student_id: 34, name: "Evan O'Neill", time: 22.998 },
    { student_id: 35, name: "Ryan Hodge", time: 24.159 },
];

export default drivers;
